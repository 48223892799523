import format from 'date-fns/format'

export const loadScript = src => {
  const tag = document.createElement('script')
  tag.setAttribute('src', src)
  tag.setAttribute('type', 'text/javascript')
  document.body.appendChild(tag)
}

export const getDateTime = (date, datetimeFormat = 'MM/dd/yyyy HH:mm') =>
  date ? format(new Date(date), datetimeFormat) : ''
